import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataTotalData:{},
  },
  getters: {
  },
  mutations: {
    setDataTotalData: (state, active) => {
      state.dataTotalData = active;
    },
  },
  actions: {

  },
  modules: {
  }
})

import { render, staticRenderFns } from "./ElementMultiInstance.vue?vue&type=template&id=4c03f220"
import script from "./ElementMultiInstance.vue?vue&type=script&lang=js"
export * from "./ElementMultiInstance.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\yudao-admin\\yudao-ui-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c03f220')) {
      api.createRecord('4c03f220', component.options)
    } else {
      api.reload('4c03f220', component.options)
    }
    module.hot.accept("./ElementMultiInstance.vue?vue&type=template&id=4c03f220", function () {
      api.rerender('4c03f220', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bpmnProcessDesigner/package/penal/multi-instance/ElementMultiInstance.vue"
export default component.exports